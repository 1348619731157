:root {
  /*SIZE*/
  ---navbar-height: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f1f1;
}

.main {
  margin-bottom: 200px;
}

.red {
  background-color: red;
}

.componentFirst {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
}

.componentSecond {
  width: 100%;
  max-width: 1100px;
  display: flex;
  margin: 0 50px;
}

.GoToTop {
  position: fixed;
  bottom: 60px;
  right: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ef2d4b;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  transition: all 0.3s;
}

.GoToTop:hover {
  transform: scale(0.9);
}

@media only screen and (max-width: 670px) {
  .componentSecond {
    margin: 0 20px;
  }
  .main {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 450px) {
  .componentSecond {
    margin: 0 10px;
  }
}
