.infoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.introAni,
.info2Ani {
  transition: all 0.75s cubic-bezier(0.645, 0.045, 0.355, 1), transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1),
    -webkit-transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
  margin-bottom: 50px;
}

.infoLeft {
  flex: 40%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
}

.infoRight {
  flex: 60%;
  padding: 70px 0;
  width: 100%;
}

.infoIMG {
  width: 100%;
  border-radius: 12px;
  max-width: 500px;
}

.infoIMGElement {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  object-position: center;
}

.info2IMGElement {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.infoTitle {
  letter-spacing: 0px;
  color: #ef2d4b;
  text-transform: uppercase;
  opacity: 1;
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
  width: 100%;
}

.infoTitleContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infoSubTitle {
  letter-spacing: 0px;
  color: #0d0d0d;
  opacity: 1;
  font-weight: 700;
  font-size: 48px;
  max-width: 650px;
  margin: 20px 0;
  font-family: 'League Spartan', sans-serif;
}

.infoText {
  letter-spacing: 0px;
  color: #8598ad;
  opacity: 1;
  font-size: 20px;
}

.infoTextSecond {
  letter-spacing: 0px;
  color: #8598ad;
  opacity: 1;
  font-size: 20px;
  max-width: 680px;
  text-align: center;
}

.infoTitleLine {
  width: 50px;
  height: 2px;
  background-color: red;
}

.infoSecond {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.infoSubTitleSecond {
  text-align: center;
}

.info2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-top: 50px;
}

.info2Left {
  flex: 44%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.info2Right {
  flex: 56%;
}

.info2Box {
  background-color: #fbfbfb;
  border-radius: 12px;
  padding: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.info2IconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info2Icon {
  border-radius: 4px;
  width: 33px;
  height: 33px;
  min-width: 33px;
}

.info2Text {
  letter-spacing: 0px;
  color: #0d0d0d;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
  font-family: 'League Spartan', sans-serif;
}

.info2Content {
  letter-spacing: 0px;
  color: #8598ad;
  opacity: 1;
  font-size: 20px;
  margin-top: 22px;
}

.info2Links {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.info2Item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.info2ItemDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ef2d4b;
}

.info2ItemText {
  letter-spacing: 0px;
  color: #0d0d0d;
  opacity: 1;
  font-weight: 600;
  font-size: 12px;
  margin-left: 8px;
}

.info2NumberContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.boxTitle {
  margin-left: 0;
}

.info2Number {
  letter-spacing: 0px;
  color: #9eadbe;
  font-weight: 600;
  font-size: 20px;
}

.info2NumberText {
  letter-spacing: 0px;
  color: #0d0d0d;
  font-weight: 600;
  font-size: 20px;
  margin-left: 10px;
}

@media only screen and (max-width: 1100px) {
  .info {
    flex-direction: column;
    width: 100%;
  }
  .infoLeft {
    width: 100%;
  }
  .infoRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }

  .infoSubTitle {
    text-align: center;
    width: 100%;
  }

  .infoText {
    text-align: center;
  }
  .infoTextFixed {
    max-width: 500px;
  }

  .info2 {
    flex-direction: column;
  }

  .info2Left {
    align-items: center;
  }
  .info2Content {
    text-align: center;
    max-width: 500px;
  }
  .infoSubTitle {
    font-size: 40px;
  }
  .infoTitle {
    font-size: 18px;
    text-align: center;
  }
  .infoText {
    font-size: 18px;
  }
  .infoTitleLine {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .infoSubTitle {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .infoSubTitle {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .infoSubTitle {
    font-size: 26px;
  }
  .infoTitle {
    font-size: 16px;
  }
  .info2Links {
    flex-direction: column;
  }
}
