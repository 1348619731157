.navbar {
  height: var(---navbar-height);
  display: flex;
  justify-content: center;
  padding: 0 50px;
  overflow: hidden;
}

.navbarContainer {
  height: 100%;
  width: 100%;
  max-width: 1450px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: TextUpOp 2s 0.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.navbarLeft {
  flex: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.navbarCenter {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbarRight {
  flex: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbarLeftLogo {
  cursor: default;
}

.navbarCenterItem {
  color: #f2f1f1;
  font-weight: 400;
  font-size: 21px;
  margin: 0 35px;
  white-space: nowrap;
  cursor: pointer;
}

.navbarCenterItem:hover,
.navbarRightItem:hover,
.navbarRightButton:hover,
.navbarCenterLink:hover {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.navbarCenterItem:active,
.navbarRightItem:active,
.navbarRightButton:active,
.navbarCenterLink:active {
  -webkit-transform: scale(1.08);
  -moz-transform: scale(1.08);
  -o-transform: scale(1.08);
  transform: scale(1.08);
}

.navbarRightItem {
  color: var(--color-text-main);
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
  margin-right: 18px;
  cursor: pointer;
}

.navbarRightButton {
  padding: 17px 45px;
  background-color: var(--color-main);
  border-radius: 28px;
  cursor: pointer;
  color: var(--color-text-main);
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
}

.navbarMobileButton {
  display: none;
}
.MobileDN {
  display: none;
}
.navbarMobile {
  display: none;
}

.navbarMobileContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  transition: z-index 1s linear;
}

.navbarMobileContainerActive {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  overflow: hidden;
}

.navbarLogoLeftTitle {
  letter-spacing: 2px;
  color: #ffffff;
  opacity: 1;
  font-size: 20px;
  font-weight: 500;
}

.navbarLogoLeftSubTitle {
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 1;
  font-size: 10px;
}

.navbarLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

@media only screen and (max-width: 1100px) {
  .navbar {
    justify-content: flex-end;
    align-items: center;
    overflow: hidden;
  }
  .navbarMobileButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbarContainer {
    display: none;
  }

  .navbarMenu {
    z-index: 999;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100vw;
    height: 200%;
    position: absolute;
    top: 0;
    right: -120%;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: hidden;
  }

  .navbarMenuContainer {
    background-color: #313437;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100vw;
    min-width: 300px;
    z-index: 999;
  }

  .navbarMenu.active {
    top: 0;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navbarMobileTop {
    display: flex;
    width: 100%;
    min-height: 80px;
  }

  .navbarCenterIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbarMobileLogoSize {
    width: 35vw;
    height: auto;
  }

  .CloseIcon {
    padding: 15px;
  }

  .navbarMobileTopLeft {
    flex: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 30px;
  }

  .navbarMobileTopRight {
    flex: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;
    font-weight: 400;
    font-size: 21px;
  }

  .menuOpen {
    margin-left: 30px;
    font-weight: 600;
  }

  .navbarMobileMain {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
  }

  .navbarCenterLink {
    text-transform: uppercase;
    color: #f2f1f1;
    font: normal;
    font-weight: 600;
    font-size: 16px;
    margin: 10px 0;
    margin-left: 30px;
    text-align: center;
  }

  .navbarLinksContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .navbarLinksContainerWrapper {
    display: flex;
    grid-row-gap: 10px;
    grid-column-gap: 0px;
    margin: 0 20px;
  }
  .navbarMobile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
  }

  .navbar {
    height: 80px;
  }
}

@media only screen and (max-width: 670px) {
  .navbar {
    padding: 0 20px;
  }
}
