.introBC123 {
  width: 110%;
  margin-top: 10px;
  min-height: 800px;
  object-fit: cover;
  object-position: center;
  z-index: -10;
}

.introNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 500px;
}

.introMain {
  margin-top: 0;
}

.intro {
  position: absolute;
  top: 400px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
}

.introContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.introTitle {
  letter-spacing: 6px;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
  font-size: 60px;
  opacity: 0;
  animation: TextUpOp 2s 1s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  font-family: 'League Spartan', sans-serif;
}

.introText {
  letter-spacing: 4.2px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 600;
  font-size: 21px;
  opacity: 0;
  animation: TextUpOp 2s 1.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.introButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
  margin-top: 20px;
  opacity: 0;
  animation: TextUpOp 2s 1.8s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.introButtonOUT {
  background-color: #f5f4f4;
  border-radius: 36px;
  width: 192px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0px;
  color: #0d0d0d;
  opacity: 1;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.introButtonOUT:hover {
  transform: scale(0.9);
}

.introButtonIN {
  border: 2px solid #f5f4f4;
  width: 188px;
  height: 54px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0px;
  color: #f5f4f4;
  opacity: 1;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}
.introButtonIN:hover {
  transform: scale(0.9);
}

.introBC123Mobile {
  display: none;
  width: 110%;
  margin-top: 10px;
  min-height: 800px;
  object-fit: cover;
  object-position: center;
  z-index: -10;
}

@media only screen and (max-width: 550px) {
  .introBC123 {
    display: none;
  }
  .introBC123Mobile {
    display: block;
  }
}

@media only screen and (max-width: 450px) {
  .introText {
    text-align: center;
  }
  .introButtons {
    flex-direction: column;
  }
  .intro {
    position: absolute;
    top: 300px;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
  }
}
